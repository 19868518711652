import { notification } from 'antd';
import { match } from 'ts-pattern';

import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { relayStylePagination } from '@apollo/client/utilities';

import type { XProps } from 'src/types/global';
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (
    operation.operationName === 'FetchResourceDetail' &&
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 403
  ) {
    networkError = undefined;
  }

  if (graphQLErrors) {
    graphQLErrors
      .filter(({ extensions }) => {
        return extensions?.exception?.name !== 'VALIDATION';
      })
      .map(({ message, extensions }) => {
        notification.error({
          message: match(extensions?.exception?.name)
            .with('UNAUTHORIZED', () => 'Permission Denied')
            .otherwise(() => 'GraphQL Error'),
          description: `${message} operation: ${operation.operationName}`,
        });
      });
  }

  if (networkError) {
    notification.error({
      message: match(networkError)
        .when(
          (e) => 'statusCode' in e && e.statusCode === 401,
          () => 'Not Authenticated',
        )
        .when(
          (e) => 'statusCode' in e && e.statusCode === 403,
          () => 'Permission Denied',
        )
        .otherwise(() => 'Network Error'),
      description: `${networkError.message} operation: ${operation.operationName}`,
    });
    if ('statusCode' in networkError && networkError.statusCode === 403) {
      window.xprops?.authorize();
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: async (error) => {
      if ('statusCode' in error && error.statusCode === 403) {
        const result = await window.xprops?.authorize();
        return !result;
      }
      return false;
    },
  },
});

const getHttpLink = (endpoint: string, xprops?: XProps) => {
  const { token, featureSwitches, userId, user, domain } = xprops ?? {};
  const headers: Record<string, string> = {
    'X-Feature-Switches': featureSwitches ? JSON.stringify(featureSwitches) : '',
    Authorization: token ? `Bearer ${token}` : '',
  };
  if (domain) headers['x-tenant-domain'] = new URL(domain).hostname;
  if (userId) headers['x-user-id'] = userId;
  if (user?.tenant) headers['x-user-tenant'] = `${user.tenant.name}:${user.tenant.level}`;
  if (/localhost/.test(endpoint)) {
    headers['x-user'] = JSON.stringify({
      userId: user?.userId || userId,
      roles: user?.roles || [],
      tenant: user?.tenant || {},
    });
  }
  return new HttpLink({
    uri: endpoint,
    headers,
  });
};

export function getGraphQLClient(endpoint: string, xprops?: XProps): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([errorLink, getHttpLink(endpoint, xprops), retryLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            resourceConnection: relayStylePagination(),
            tagConnection: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}
