export const defaultTheme = {
  name: 'default',
  breakpoints: ['768px', '1288px'],
  colors: {
    accent: '#464ac9',
    accentSecondary: '#aaacc0',
    background: '#f6f7fa',
    bgHighlight: 'rgba(69, 79, 194, 0.07)',
    bgWhite: '#fff',
    bgBlue: '#464ac9',
    bgHeader: 'linear-gradient(to bottom, rgba(70, 74, 201, 1), rgba(70, 74, 201, 0.85))',
    textWhite: '#e4e7ed',
    title: '#1d1e2b',
    textAccent: '#73747d',
    border: '#e4e7ed',
    buttonGreen: '#12c39a',
    buttonBlue: '#464ac9',
    borderBlue: '#464ac9',
  },
  fonts: {
    default: 'Montserrat',
    bold: 'Montserrat-Bold',
  },
  fontSizes: {
    default: '14px',
  },
  radii: {
    default: 8,
  },
  shadows: {
    buttonHome: '0 3px 6px 0 rgba(69, 79, 194, 0.18)',
  },
  zIndices: {},
};
